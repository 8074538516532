const socialShareEndpoints = {
    'email': 'mailto:?subject=&body=',
    'facebook': 'https://facebook.com/sharer/sharer.php?u=',
    'linkedin': 'https://www.linkedin.com/sharing/share-offsite/?url=',
    'pinterest': 'https://pinterest.com/pin/create/button/?url=&media=&description=',
    'reddit': 'https://reddit.com/submit/?url=&resubmit=true&title=',
    'twitter': 'https://x.com/intent/post/?text=&url=&via=NIDDKgov',
    'x': 'https://x.com/intent/post/?text=&url=&via=NIDDKgov',
    'whatsapp': 'whatsapp://send?text='
};

const copyAlertText = {
    'en': [
        'The sharing link was copied to the clipboard.',
        'Unable to copy the sharing link to the clipboard. You may be using a browser that does not allow it.'
    ],
    'es': [
        'El enlace para compartir se copió en el portapapeles.',
        'No se pudo copiar el enlace para compartir en el portapapeles. Puede que esté utilizando un navegador que no lo permite.'
    ],
    'fr': [
        'Le lien de partage a été copié dans le presse-papiers.',
        'Impossible de copier le lien de partage dans le presse-papiers. Vous utilisez peut-être un navigateur qui ne le permet pas.'
    ]
};

for (const element of document.querySelectorAll('.dk-share-buttons')) {
    initialize({element});
}

export default function initialize({element, url, title}) {
    // Make sure the wrapping element/selector is provided in the object or as the first argument
    element = element || arguments[0];
    if (!element) return;
    if (typeof element === 'string' && element[0] === '#') element = document.querySelectorAll(element)[0];
    if (!(element instanceof Element)) return;

    let lang = element.dataset.lang || 'en';
    if (document.querySelector('body.page-lang-es')) lang = 'es';

    // Determine the URL to share (either provided or derived from the current location)
    const linkUrl = new window.URL(url || element.dataset.url || location.href, location.href);
    const linkParams = linkUrl.searchParams;
    linkParams.delete('dkrd');

    // Add tracking parameters to the URL
    linkParams.set('utm_source', '');
    linkParams.set('utm_medium', 'sharing button');
    linkParams.set('utm_content', linkUrl.pathname);

    // Determine the page title to share (either provided or derived from the H1 or the document title)
    title = (title || element.dataset.title || (function () {
        const elt = document.querySelectorAll('main h1,head title')[0];
        return elt ? (elt.innerText || '').replace(/\s*\|\s*NIDDK/i, '') : '';
    })()).trim();

    // Helper to lazily search for image URL if needed
    const getImageUrl = (function () {
        let imageUrl;
        return function () {
            if (imageUrl) return imageUrl;
            const meta = document.querySelectorAll('body>meta[itemprop="image"],meta[property*=":image"]')[0];
            if (!meta || !meta.content) return null;
            return imageUrl = (new window.URL(meta.content, location.href)).toString();
        };
    })();

    const generateId = (function() {
        let generatedIds = [];
        return function(elt, replace) {
            const hasElt = elt instanceof Element;
            if (hasElt && elt.id && !replace) return elt.id;
            let id;
            do id = 'id' + Math.floor(Math.random() * 1000000).toString(36);
            while (document.getElementById(id) || generatedIds.includes(id));
            generatedIds.push(id);
            if (hasElt) elt.id = id;
            return id;
        };
    })();

    for (const button of element.children) {
        // Determine button type; skip unknown
        const source = (button.className.match(/\bshr-(.+?)\b/i) || [])[1] || '';

        // Update tracking source
        linkParams.set('utm_source', source);

        // Handle special buttons
        switch (source) {
            case 'more':
                button.setAttribute('aria-expanded', false);
                button.parentElement.classList.remove('expanded');
                button.addEventListener('click', function () {
                    this.setAttribute('aria-expanded', this.parentElement.classList.toggle('expanded'));
                });
                continue;
            case 'copy':
                (function () {
                    const successText = button.dataset.successText || copyAlertText[lang][0],
                        errorText = button.dataset.errorText || copyAlertText[lang][1];
                    button.removeAttribute('data-success-text');
                    button.removeAttribute('data-error-text');
                    button.addEventListener('click', function () {
                        const url = linkUrl.toString();
                        try {
                            navigator.clipboard.writeText(url).then(function() {
                                window.alert(successText);
                            }, function () {
                                window.alert(errorText);
                            });
                        } catch (_) {
                            window.alert(errorText);
                        }
                    });
                })();
                continue;
            case 'x-social':
            case 'twitter':
                button.addEventListener('click', function (event) {
                    // If the X (Twitter) widget JS is on the page, avoid a conflict that opens the share window twice
                    if (window.twttr) {
                        if (event.stopPropagation) event.stopPropagation(); // W3C model
                        else event.cancelBubble = true; // legacy model
                        return false;
                    }
                });
                break;
        }

        // Ensure the other buttons are defined social sharing destinations
        if (!Object.keys(socialShareEndpoints).includes(source)) continue;

        // Set parameter values in sharing endpoint URLs
        const buttonUrl = new window.URL(socialShareEndpoints[source], socialShareEndpoints[source]);
        const buttonParams = buttonUrl.searchParams;
        for (const [key] of buttonParams) {
            switch (`${buttonUrl.protocol}${key}`) {
                case 'https:u':
                case 'https:url':
                    buttonParams.set(key, linkUrl.toString());
                    break;
                case 'whatsapp:text':
                case 'mailto:body':
                    buttonParams.set(key, `"${title}" ${(lang.match(/es|fr/) ? 'de' : 'from')} NIDDK: ${linkUrl.toString()}`);
                    break;
                case 'https:text':
                case 'https:title':
                case 'mailto:subject':
                case 'https:description':
                    buttonParams.set(key, title);
                    break;
                case 'https:media':
                    (function () {
                        const imageUrl = getImageUrl();
                        if (imageUrl) buttonParams.set(key, imageUrl);
                        else buttonParams.delete(key);
                    })();
                    break;
            }
        }

        // Replace + with %20 in email links
        if (buttonUrl.protocol.match(/^mailto/i)) {
            buttonUrl.search = buttonUrl.search.replace(/\+/g, '%20');
        }

        button.href = buttonUrl.toString();
    }

    // Update the aria-controls of the More button
    const moreButton = element.querySelector('.shr-more');
    if (moreButton) {
        const ids = [];
        let button = moreButton;
        while ((button = button.nextElementSibling))
            ids.push(generateId(button));
        moreButton.setAttribute('aria-controls', ids.join(' '));
    }

    // Indicate that the component is ready (make it visible)
    element.parentElement.classList.add('ready');
}
